import React from 'react';
import { Link } from 'react-router-dom'
import OpenLayers from './OpenLayers';

// eslint-disable-next-line
export default () => {
    return (
        <div style={{ width: '100%', height: '100vh', boxSizing: 'border-box' }}>
            <Link to="/">Вернуться в меню</Link> | <a href="http://wiki.office.custis.ru/wiki/index.php/%D0%93%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B8_%D0%B8_%D0%B4%D0%B0%D1%88%D0%B1%D0%BE%D1%80%D0%B4%D1%8B_%D0%BD%D0%B0_javascript">Описание проекта</a>
            <h1>OpenLayers</h1>
            <OpenLayers />
        </div>
    );
}