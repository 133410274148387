import React from 'react';
import Chart from './Chart'
import './style.css';

// eslint-disable-next-line
export default ({ contractCostChange }) => {
    return <React.Fragment>
        <h2>2.3 Изменение стоимости договора - В3 (<a href="https://www.chartjs.org/docs/latest/samples/bar/floating.html">Chart.js Floating Bars</a>)</h2>
        {contractCostChange.map((i, idx) => (
            <div key={idx}>
                <ContractCostChange1 contractCostChange={i} />
            </div>
        ))}
    </React.Fragment>
}

const ContractCostChange1 = ({ contractCostChange }) => {
    let currenciesCnt = contractCostChange.start.length;
    let start = contractCostChange.start.map(_ => _.value);
    let changes = contractCostChange.changes.map(_ => {
        return _.changes.map((c, idx) => {
            let r = {
                value: c.value,
                range: c.value < 0 ? [start[idx] + c.value, start[idx]] : [start[idx], start[idx] + c.value]
            }
            start[idx] = start[idx] + c.value;
            return r;
        })
    });
    return <Chart
        type='bar'
        maxHeight={300}
        maxWidth={600}
        data={{
            labels: [
                ...contractCostChange.start.map(_ => `ИТОГ (${_.currency})`),
                ...contractCostChange.changes.flatMap(_ => {
                    return contractCostChange.start.map(c => `${_.name} (${c.currency})`)
                })
            ],
            datasets: [{
                label: 'Начальная стоимость договора',
                data: [...contractCostChange.start.map(_ => _.value)],
                backgroundColor: [
                    'rgba(255, 204, 182, 0.5)'
                ],
                borderWidth: 1,
                datalabels: {
                    anchor: 'end',
                    align: 'top',
                    formatter: (value, context) => value
                }
            }, {
                label: 'Уменьшение',
                data: [
                    ...contractCostChange.start.map((c, idx) => start[idx] < contractCostChange.start[idx].value ? [start[idx], contractCostChange.start[idx].value] : null),
                    ...contractCostChange.start.flatMap((c, idx) => changes.map(_ => (_[idx].value < 0 ? _[idx].range : null)))
                ],
                backgroundColor: [
                    'rgba(233, 161, 161, 1)'
                ],
                borderWidth: 1,
                borderSkipped: 'top',
                borderRadius: 10,
                datalabels: {
                    anchor: 'start',
                    align: 'bottom',
                    color: 'rgba(233, 161, 161, 1)',
                    formatter: (value, context) => {
                        return value 
                            ? context.dataIndex < currenciesCnt
                                ? `${value[0]} (-${value[1] - value[0]})` 
                                : `-${value[1] - value[0]}` 
                            : null
                    }
                }
            }, {
                label: 'Увеличение',
                data: [
                    ...contractCostChange.start.map((c, idx) => start[idx] > contractCostChange.start[idx].value ? [contractCostChange.start[idx].value, start[idx]] : null),
                    ...contractCostChange.start.flatMap((c, idx) => changes.map(_ => (_[idx].value > 0 ? _[idx].range : null)))
                ],
                backgroundColor: [
                    'rgba(187, 197, 208, 1)'
                ],
                borderWidth: 1,
                borderSkipped: 'bottom',
                borderRadius: 10,
                datalabels: {
                    display: true,
                    anchor: 'end',
                    align: 'top',
                    color: 'rgba(187, 197, 208, 1)',
                    formatter: (value, context) => {
                        return value 
                            ? context.dataIndex < currenciesCnt
                                ? `${value[1]} (+${value[1] - value[0]})` 
                                : `+${value[1] - value[0]}` 
                            : null
                    }
                }
            }
            ]
        }}
        options={{
            plugins: {
                datalabels: {
                    display: true,
                    anchor: 'end',
                    align: 'top'
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: true
                    }
                },
                yAxes: {
                    display: false
                }
            }
        }} />
}