import React, {useState} from 'react';
import { AgChartsReact } from 'ag-charts-react';
import './style.css';

// eslint-disable-next-line
export default ({contractCostChange}) => {
    const [axesEnabled, setAxesEnabled] = useState(true);
    const [labelEnabled, setLabelEnabled] = useState(false);
    const [gridEnabled, setGridEnabled] = useState(false);
    return <React.Fragment>
        <h2>2.1 Изменение стоимости договора - В1 (<a href="https://www.ag-grid.com/react-charts/gallery/stacked-column/">AgChart Stacked Column</a> - договора в одном, валюты в разных)</h2>
        <input type="checkbox" checked={axesEnabled} onChange={(e) => {setAxesEnabled(e.target.checked)}} /><label>Оси</label>
        <input type="checkbox" checked={gridEnabled} onChange={(e) => {setGridEnabled(e.target.checked)}} /><label>Сетка</label>
        <input type="checkbox" checked={labelEnabled} onChange={(e) => {setLabelEnabled(e.target.checked)}} /><label>Подписи</label>
        {contractCostChange.map((i, idx) => (
            <div key={idx}>
                <ContractCostChange2 contractCostChange={i} axesEnabled={axesEnabled} labelEnabled={labelEnabled} gridEnabled={gridEnabled} />
            </div>
            ))}
        <h2>2.2 Изменение стоимости договора - В2 (<a href="https://www.ag-grid.com/react-charts/gallery/stacked-column/">AgChart Stacked Column</a> - договора в разных, валюты в одном)</h2>
        {contractCostChange.map((i, idx) => (
            <div key={idx}>
                <ContractCostChange1 contractCostChange={i} axesEnabled={axesEnabled} labelEnabled={labelEnabled} gridEnabled={gridEnabled} />
            </div>
            ))}
    </React.Fragment>
}

const ContractCostChange1 = ({contractCostChange, axesEnabled, labelEnabled, gridEnabled}) => {
    let axis = {}
    return <div className="lineWrapper">
        <AggrBlock contractCostChange={contractCostChange} axis={axis} axesEnabled={axesEnabled} labelEnabled={labelEnabled} gridEnabled={gridEnabled} />
        <ChangeBlock contractCostChange={contractCostChange} axis={axis} axesEnabled={axesEnabled} labelEnabled={labelEnabled} gridEnabled={gridEnabled} />
    </div>
}

const AggrBlock = ({ contractCostChange, axis, axesEnabled, labelEnabled, gridEnabled }) => {
    var data = [];
    axis.max = 0;
    for (let c of contractCostChange.start) {
        let currency = c.currency;
        let start = c.value;
        let max = start;
        let delta = 0;
        for (let ch of contractCostChange.changes) {
            let d = ch.changes.filter(i => i.currency === currency)[0].value;
            delta += d;
            max = max + d;
            if (max > axis.max) axis.max = max;
        }
        data.push({
            currency: currency,
            v1: delta < 0 ? start + delta : start,
            v2: delta < 0 ? -delta : undefined,
            v3: delta > 0 ? delta : undefined
        })
    }
    
    const options = {
        autoSize: true,
        data: data,
        title: {
            text: 'ИТОГ',
            fontSize: 18,
        },
        series: [
            {
                type: 'column',
                xKey: 'currency',
                yKeys: [
                    'v1',
                    'v2',
                    'v3'
                ],
                yNames: [
                    ' ',
                    ' ',
                    ' '
                ],
                fills: ['#FFCCB6', '#E9A1A1', '#BBC5D0'],
                strokes: ['#FFCCB6', '#E9A1A1', '#BBC5D0'],
                label: {
                    enabled: labelEnabled,
                    placement: 'outside'
                }
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
            },
            {
                type: 'number',
                position: 'left',
                max: axis.max
            },
        ],
        legend: {
            enabled: false
        },
    }
    if (gridEnabled === false) {
        options.axes[0].gridStyle = {stroke: 'transparent', lineDash: null};
        options.axes[1].gridStyle = {stroke: 'transparent', lineDash: null};
    }
    if (axesEnabled === false) {
        options.axes[0].line = {width: 0};
        options.axes[1].line = {width: 0};
        options.axes[1].label = {color: 'transparent'};
    }
    return <AgChartsReact options={options} />
}

const ChangeBlock = ({ contractCostChange, axis, axesEnabled, labelEnabled, gridEnabled }) => {
    var data = [];
    let dataDelta = [];
    for (let c of contractCostChange.start) {
        dataDelta.push({
            currency: c.currency,
            current: c.value
        })
    }
    let current = dataDelta;

    for (let ch of contractCostChange.changes) {
        dataDelta = [];
        for (let c of current) {
            let delta = ch.changes.filter(i => i.currency === c.currency)[0].value;
            dataDelta.push({
                name: ch.name,
                currency: c.currency,
                current: c.current + delta,
                delta: delta,
                v1: delta < 0 ? c.current + delta : c.current,
                v2: delta < 0 ? -delta : undefined,
                v3: delta > 0 ? delta : undefined
            })
        }
        data.push(dataDelta);
        current = dataDelta;
    }
    const options = {
        autoSize: true,
        data: data,
        title: {
            text: 'ИТОГ',
            fontSize: 18,
        },
        series: [
            {
                type: 'column',
                xKey: 'currency',
                yKeys: [
                    'v1',
                    'v2',
                    'v3'
                ],
                yNames: [
                    ' ',
                    ' ',
                    ' '
                ],
                fills: ['transparent', '#E9A1A1', '#BBC5D0'],
                strokes: ['transparent', '#E9A1A1', '#BBC5D0'],
                label: {
                    enabled: labelEnabled,
                    placement: 'outside'
                }
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
            },
            {
                type: 'number',
                position: 'left',
                max: axis.max
            },
        ],
        legend: {
            enabled: false
        },
    }
    if (gridEnabled === false) {
        options.axes[0].gridStyle = {stroke: 'transparent', lineDash: null};
        options.axes[1].gridStyle = {stroke: 'transparent', lineDash: null};
    }
    if (axesEnabled === false) {
        options.axes[0].line = {width: 0};
        options.axes[1].line = {width: 0};
        options.axes[1].label = {color: 'transparent'};
    }
    return <table><tbody>
        <tr>
            {data.map((i, idx) => {
                return <td key={idx}><div style={{width: '200px'}}><AgChartsReact options={{...options, data: i, title: {...options.title, text: i[0].name}}} /></div></td>
            })}
            <td style={{width: '100%'}}></td>
        </tr>
    </tbody></table>
}

//--------------------------
const ContractCostChange2 = ({contractCostChange, axesEnabled, labelEnabled, gridEnabled}) => {
    return <div className="lineWrapper2">
        {contractCostChange.start.map((i, idx) => (
            <Block2 key={idx} contractCostChange={contractCostChange} currency={i.currency} axesEnabled={axesEnabled} labelEnabled={labelEnabled} gridEnabled={gridEnabled} />
        ))}
        <div style={{width: '100%'}}></div>
    </div>
}
const Block2 = ({ contractCostChange, currency, axesEnabled, labelEnabled, gridEnabled }) => {
    let data = [];
    let dataDelta = {}

    for (let c of contractCostChange.start.filter(_ => _.currency === currency)) {
        let currency = c.currency;
        let start = c.value;
        let max = start;
        let delta = 0;
        for (let ch of contractCostChange.changes) {
            let d = ch.changes.filter(i => i.currency === currency)[0].value;
            delta += d;
            max = max + d;
        }
        dataDelta = {
            name: 'ИТОГ',
            current: start,
            currency: currency,
            v0: delta < 0 ? start + delta : start,
            v1: undefined,
            v2: delta < 0 ? -delta : undefined,
            v3: delta > 0 ? delta : undefined
        }
        data.push(dataDelta)
    }

    let current = dataDelta;
    for (let ch of contractCostChange.changes) {
        let delta = ch.changes.filter(i => i.currency === currency)[0].value;
        dataDelta = {
            name: ch.name,
            currency: current.currency,
            current: current.current + delta,
            delta: delta,
            v0: undefined,
            v1: delta < 0 ? current.current + delta : current.current,
            v2: delta < 0 ? -delta : undefined,
            v3: delta > 0 ? delta : undefined
        }
        data.push(dataDelta);
        current = dataDelta;
    }
    const options = {
        autoSize: true,
        data: data,
        title: {
            text: currency,
            fontSize: 18,
        },
        //subtitle: { text: 'Source: Transport for London' },
        series: [
            {
                type: 'column',
                xKey: 'name',
                yKeys: [
                    'v0',
                    'v1',
                    'v2',
                    'v3'
                ],
                yNames: [
                    'Начальная стоимость договора',
                    ' ',
                    'Уменьшение',
                    'Увеличение'
                ],
                fills: ['#FFCCB6', 'transparent', '#E9A1A1', '#BBC5D0'],
                strokes: ['#FFCCB6', 'transparent', '#E9A1A1', '#BBC5D0'],
                label: {
                    enabled: labelEnabled,
                    placement: 'outside'
                }
            }
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom'
            },
            {
                type: 'number',
                position: 'left'
            },
        ]
    }
    if (gridEnabled === false) {
        options.axes[0].gridStyle = {stroke: 'transparent', lineDash: null};
        options.axes[1].gridStyle = {stroke: 'transparent', lineDash: null};
    }
    if (axesEnabled === false) {
        options.axes[0].line = {width: 0};
        options.axes[1].line = {width: 0};
        options.axes[1].label = {color: 'transparent'};
    }
    return <AgChartsReact options={options} />
}
