import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom'
import Menu from './Menu'
import PkiDashboards from './PkiDashboards/PkiDashboards'
import Gis from './Gis'

function App() {
    return <BrowserRouter>
        {/*<Route exact path="/"><Redirect to="/PkiDashboards" /></Route>*/}
        <Route exact path="/" render={(props) => <Menu {...props} />} />
        <Route path='/PkiDashboards' render={(props) => <PkiDashboards {...props} />} />
        <Route path='/Gis' render={(props) => <Gis {...props} />} />
    </BrowserRouter>
}

export default App;
