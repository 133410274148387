// react
import React, { useState, useEffect } from 'react';

// components
import MapWrapper from './MapWrapper'

import 'ol/ol.css';
import OSM from 'ol/source/OSM'
import KML from 'ol/format/KML';
import Map from 'ol/Map';
import View from 'ol/View';
import {
    Circle as CircleStyle,
    Fill,
    RegularShape,
    Stroke,
    Style,
    Text
} from 'ol/style';
import { Cluster, Stamen, Vector as VectorSource } from 'ol/source';
import {
    Select,
    defaults as defaultInteractions,
} from 'ol/interaction';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { createEmpty, extend, getHeight, getWidth } from 'ol/extent';
import { transform, fromLonLat } from 'ol/proj'
import TopoJSON from 'ol/format/TopoJSON';
import topojsonObject from './shops.json'
import Chart from 'ol-ext/style/Chart'

const earthquakeFill = new Fill({
    color: 'rgba(255, 153, 0, 0.8)',
});
const earthquakeStroke = new Stroke({
    color: 'rgba(255, 204, 0, 0.2)',
    width: 1,
});
const textFill = new Fill({
    color: '#fff',
});
const textStroke = new Stroke({
    color: 'rgba(0, 0, 0, 0.6)',
    width: 3,
});
const invisibleFill = new Fill({
    color: 'rgba(255, 255, 255, 0.01)',
});

function createEarthquakeStyle(feature) {
    // 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
    // standards-violating <magnitude> tag in each Placemark.  We extract it
    // from the Placemark's name instead.
    const name = feature.get('name');
    const magnitude = parseFloat(name.substr(2));
    const radius = 5 + 20 * (magnitude - 5);

    return new Style({
        geometry: feature.getGeometry(),
        image: new RegularShape({
            radius1: radius,
            radius2: 3,
            points: 3,
            angle: Math.PI,
            fill: earthquakeFill,
            stroke: earthquakeStroke,
        }),
    });
}

let maxFeatureCount;
let vector = null;
const calculateClusterInfo = function (resolution) {
    maxFeatureCount = 0;
    const features = vector.getSource().getFeatures();
    let feature, radius;
    for (let i = features.length - 1; i >= 0; --i) {
        feature = features[i];
        const originalFeatures = feature.get('features');
        const extent = createEmpty();
        let j, jj;
        for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
            extend(extent, originalFeatures[j].getGeometry().getExtent());
        }
        maxFeatureCount = Math.max(maxFeatureCount, jj);
        radius = (0.25 * (getWidth(extent) + getHeight(extent))) / resolution;
        feature.set('radius', radius);
    }
};

let currentResolution;
function styleFunction(feature, resolution) {
    if (resolution != currentResolution) {
        calculateClusterInfo(resolution);
        currentResolution = resolution;
    }
    let style;
    const size = feature.get('features').length;
    const fill = new Fill({
        color: 'rgba(255,255,255,0.4)',
    });
    const stroke = new Stroke({
        color: '#3399CC',
        width: 1.25,
    });
    let radius = Math.ceil(size / 10) * 5
    const colors = ["#e73b3b", "#649ceb", "#faac18", "#79d16b", ""]
    const data = [0, 0, 0, 0]
    const features = feature.get('features')
    for (let i = features.length - 1; i >= 0; --i) {
        feature = features[i];
        data[0] += feature.get('v1');
        data[1] += feature.get('v2');
        data[2] += feature.get('v3');
        data[3] += feature.get('v4');
    }
    return new Style({
        geometry: feature.getGeometry(),
        /*image: new CircleStyle({
            fill: fill,
            stroke: stroke,
            radius: radius,
        }),*/
        image: new Chart({
            type: 'donut',
            data: data,
            colors: colors,
            rotateWithView: true,
            radius: 20,
            stroke: new Stroke(
                {
                    color: "#fff",
                    width: 1
                }),

        }),
        text: new Text({
            text: size.toString(),
            fill: textFill,
            stroke: textStroke,
        }),
        fill: fill,
        stroke: stroke,
        /*renderer(coordinates, state) {
            const [[x, y], [x1, y1]] = coordinates;
            const ctx = state.context;
            const dx = x1 - x;
            const dy = y1 - y;
            const radius = Math.sqrt(dx * dx + dy * dy);

            const innerRadius = 0;
            const outerRadius = radius * 1.4;

            const gradient = ctx.createRadialGradient(
                x,
                y,
                innerRadius,
                x,
                y,
                outerRadius
            );
            gradient.addColorStop(0, 'rgba(255,0,0,0)');
            gradient.addColorStop(0.6, 'rgba(255,0,0,0.2)');
            gradient.addColorStop(1, 'rgba(255,0,0,0.8)');
            ctx.beginPath();
            ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
            ctx.fillStyle = gradient;
            ctx.fill();

            ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
            ctx.strokeStyle = 'rgba(255,0,0,1)';
            ctx.stroke();
        }*/
    });
    if (size > 1) {
        style = new Style({
            image: new CircleStyle({
                radius: size * 5,
                fill1: new Fill({
                    color: [255, 153, 0, Math.min(0.8, 0.4 + size / maxFeatureCount)],
                }),
            }),
            text: new Text({
                text: size.toString(),
                fill: textFill,
                stroke: textStroke,
            }),
        });
    } else {
        const originalFeature = feature.get('features')[0];
        //style = createEarthquakeStyle(originalFeature);
    }
    return style;
}

function selectStyleFunction(feature) {
    const styles = [
        new Style({
            image: new CircleStyle({
                radius: feature.get('radius'),
                fill: invisibleFill,
            }),
        }),
    ];
    const originalFeatures = feature.get('features');
    let originalFeature;
    for (let i = originalFeatures.length - 1; i >= 0; --i) {
        originalFeature = originalFeatures[i];
        styles.push(createEarthquakeStyle(originalFeature));
    }
    return styles;
}

vector = new VectorLayer({
    source: new Cluster({
        distance: 50,
        source: new VectorSource({
            url: 'https://openlayers.org/en/latest/examples/data/kml/2012_Earthquakes_Mag5.kml',
            format: new KML({
                extractStyles: false,
            }),
        }),
    }),
    //style: styleFunction,
});

const vector2 = new VectorLayer({
    source: new Cluster({
        distance: 50,
        source: new VectorSource({
            features: new TopoJSON().readFeatures(topojsonObject, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })
        }),
    }),
    style: styleFunction,
});

const raster = new TileLayer({
    source: new OSM()
});

// eslint-disable-next-line
export default () => {


    return (
        <div className="App">
            <MapWrapper options={{
                layers: [raster, vector2],
                /*interactions: defaultInteractions().extend([
                    new Select({
                        condition: function (evt) {
                            return evt.type == 'pointermove' || evt.type == 'singleclick';
                        },
                        style: selectStyleFunction,
                    }),
                ]),*/
                target: 'map',
                view: new View({
                    //projection: 'EPSG:4326',
                    center: fromLonLat([37.16, 55.604]),
                    zoom: 4
                }),
            }} />
        </div>
    )
}