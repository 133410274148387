import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// eslint-disable-next-line
export default ({ className, maxHeight, maxWidth, type, data, options }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            new Chart(chartRef.current.getContext("2d"), {
                plugins: [ChartDataLabels],
                type,
                data,
                options,
            });
        }
    }, [type, data, options]);

    let style = {};
    if (maxHeight) style = {...style, maxHeight}
    if (maxWidth) style = {...style, maxWidth}

    return (
        <div className={className || ""}>
            <canvas ref={chartRef} style={style} />
        </div>
    );
}