import React from 'react';
import { Link } from 'react-router-dom'
import ContractExecutionStateAggr from './AgCharts/contractExecutionStateAggr'
import ContractExecutionStateAggrChartJs from './ChartJs/contractExecutionStateAggr'
import ContractCostChange from './AgCharts/contractCostChange'
import ContractCostChangeChartJs from './ChartJs/contractCostChange'
import ContractExecutionState from './AgCharts/contractExecutionState'
import ContractExecutionStateChartJs from './ChartJs/contractExecutionState'
import Task from './AgCharts/task'
import TaskChartJs from './ChartJs/task'
import { contractCostChange, contractExecutionState, contractExecutionStateAggr, task } from './data/data';

// eslint-disable-next-line
export default () => {
    return (
        <div style={{ width: '100%', height: '100vh', boxSizing: 'border-box' }}>
            <Link to="/">Вернуться в меню</Link> | <a href="http://wiki.office.custis.ru/wiki/index.php/%D0%94%D0%90-%D0%9E%D0%9F._%D0%9F%D0%9A%D0%98._%D0%94%D0%B0%D1%88%D0%B1%D0%BE%D1%80%D0%B4%D1%8B">Описание проекта</a>
            <h1>Статус исполнения договора</h1>
            <ContractExecutionStateAggr contractExecutionStateAggr={contractExecutionStateAggr} />
            <ContractExecutionStateAggrChartJs contractExecutionStateAggr={contractExecutionStateAggr} />
            <h1>Изменение стоимости договора</h1>
            <ContractCostChange contractCostChange={contractCostChange} />
            <ContractCostChangeChartJs contractCostChange={contractCostChange} />
            <h1>Исполнение по договорам</h1>
            <ContractExecutionState contractExecutionState={contractExecutionState} />
            <ContractExecutionStateChartJs contractExecutionState={contractExecutionState} />
            <h1>Статус зачета авансовых платежей</h1>
            <h2>4.1 Статус зачета авансовых платежей - B1 (<a href="https://www.ag-grid.com/react-charts/gallery/stacked-bar/">AgChart Stacked Bar</a>)</h2>
            <div>См. Исполнение по договорам ...</div>
            <h1>Исполнение обязательств</h1>
            <h2>5.1 Исполнение обязательств - B1 (<a href="https://www.ag-grid.com/react-charts/gallery/grouped-column/">AgChart Grouped Column</a>)</h2>
            <div>См. Статус исполнения договора</div>
            <h1>Статус претензионной работы</h1>
            <h2>6.1 Статус претензионной работы - B1 (<a href="https://www.ag-grid.com/react-charts/gallery/100--stacked-bar/">AgChart 100% Stacked Bar</a>)</h2>
            <div>См. Исполнение по договорам ...</div>
            <h1>Задачи</h1>
            <Task task={task} />
            <TaskChartJs task={task} />
        </div>
    );
}