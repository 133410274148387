import React, {useState} from 'react';
import { AgChartsReact } from 'ag-charts-react';
import './style.css';

// eslint-disable-next-line
export default ({contractExecutionState}) => {
    const [axesEnabled, setAxesEnabled] = useState(true);
    const [labelEnabled, setLabelEnabled] = useState(false);
    const [gridEnabled, setGridEnabled] = useState(false);
    return <React.Fragment>
        <h2>3.1 Исполнение по договорам - В1 (<a href="https://www.ag-grid.com/react-charts/gallery/stacked-bar/">AgChart Stacked Bar</a>)</h2>
        <input type="checkbox" checked={axesEnabled} onChange={(e) => {setAxesEnabled(e.target.checked)}} /><label>Оси</label>
        <input type="checkbox" checked={gridEnabled} onChange={(e) => {setGridEnabled(e.target.checked)}} /><label>Сетка</label>
        <input type="checkbox" checked={labelEnabled} onChange={(e) => {setLabelEnabled(e.target.checked)}} /><label>Подписи</label>
        <ContractExecutionState1 contractExecutionState={contractExecutionState} axesEnabled={axesEnabled} labelEnabled={labelEnabled} gridEnabled={gridEnabled} />
    </React.Fragment>
}

const ContractExecutionState1 = ({contractExecutionState, axesEnabled, labelEnabled, gridEnabled}) => {
    return contractExecutionState.map((c, idx) => <div key={idx}>
        <Block contractExecutionState={c} axesEnabled={axesEnabled} labelEnabled={labelEnabled} gridEnabled={gridEnabled} />
    </div>)
}

const Block = ({ contractExecutionState, axesEnabled, labelEnabled, gridEnabled }) => {
    let data = [];
    let c = contractExecutionState.byCurrency[0];
    data.push({
        block: 'Работы',
        workFact: c.workFact,
        workRest: c.workRest, 
        paidPrepaidExpense: undefined,
        paidFact: undefined,
        paidDeferred: undefined
    });
    data.push({
        block: 'Оплаты', 
        workFact: undefined,
        workRest: undefined, 
        paidPrepaidExpense: c.paidPrepaidExpense,
        paidFact: c.paidFact,
        paidDeferred: c.paidDeferred
    });

    const options = {
        autoSize: true,
        data: data,
        series: [
            {
                type: 'bar',
                xKey: 'block',
                yKeys: [
                    'workFact',
                    'workRest',
                    'paidPrepaidExpense',
                    'paidFact',
                    'paidDeferred'
                ],
                yNames: [
                    'Фактически исполнено обязательств',
                    'Текущий остаток по договору',
                    'Зачет аванса',
                    'Фактический платеж',
                    'Отложенные платежи и удержания'
                ],
                fills: ['#6683A4', '#97C1F2', '#76C7C2', '#FFCCB6', '#E9A1A1'],
                strokes: ['#6683A4', '#97C1F2', '#76C7C2', '#FFCCB6', '#E9A1A1'],
                label: {
                    enabled: labelEnabled,
                    placement: 'inside'
                }
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'left',
                label: {
                    formatter: () => ''
                }
            },
            {
                type: 'number',
                position: 'top'
            },
        ]
    }
    if (gridEnabled === false) {
        options.axes[0].gridStyle = {stroke: 'transparent', lineDash: null};
        options.axes[1].gridStyle = {stroke: 'transparent', lineDash: null};
    }
    if (axesEnabled === false) {
        options.axes[0].line = {width: 0};
        options.axes[1].line = {width: 0};
        options.axes[1].label = {color: 'transparent'};
    }
    return <div style={{height: 120}}><AgChartsReact options={options} /></div>
}