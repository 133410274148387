import React from 'react';
import { AgChartsReact } from 'ag-charts-react';
import './style.css';

// eslint-disable-next-line
export default ({task}) => {
    return <React.Fragment>
        <h2>7.1 Задачи - B1 (<a href="https://www.ag-grid.com/react-charts/gallery/simple-doughnut/">AgChart Simple Doughnut</a>)</h2>
        <Task1 task={task} />
    </React.Fragment>
}

const Task1 = ({ task }) => {
    let options = {
        autoSize: true,
        data: [{
            type: 'Активные',
            'all': task.inWork,
            'withProblem': task.inWorkWithProblem
          },{
            type: ' ',
            'all': 0,
            'withProblem': task.inWork - task.inWorkWithProblem
          },{
            type: 'Завершенные',
            'all': task.completed,
            'withProblem': task.completedWithProblem
          },{
            type: ' ',
            'all': 0,
            'withProblem': task.completed  - task.completedWithProblem
          }],
        series: [
            {
                type: 'pie',
                labelKey: 'type',
                angleKey: 'all',
                label: { enabled: false },
                title: { text: 'Всего', enabled: false },
                outerRadiusOffset: -10,
                innerRadiusOffset: -20,
                fills: ['#97C1F2', '', '#6683A4', ''],
                strokes: ['#97C1F2', '', '#6683A4', '']
            },
            {
                type: 'pie',
                labelKey: 'type',
                angleKey: 'withProblem',
                showInLegend: false,
                label: { enabled: false },
                title: { text: 'Проблемных', enabled: false },
                outerRadiusOffset: -30,
                innerRadiusOffset: -40,
                fills: ['#E55324', 'transparent', '#E55324', 'transparent'],
                strokes: ['#E55324', 'transparent', '#E55324', 'transparent']
            },
        ],
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        },
        title: {enabled: false},
        subtitle: {enabled: false}
    };
    return <div style={{width: '400px', height: '400px'}}><AgChartsReact options={options} /></div>;
}