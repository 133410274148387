import React from 'react';
import Chart from './Chart'
import './style.css';

// eslint-disable-next-line
export default ({ contractExecutionStateAggr }) => {
    return <React.Fragment>
        <h2>1.2 Статус исполнения договора - В2 (<a href="https://www.chartjs.org/docs/latest/samples/bar/vertical.html">Chart.js Vertical Bar Chart</a>)</h2>
        <ContractExecutionStateAggr1 contractExecutionStateAggr={contractExecutionStateAggr} />
    </React.Fragment>
}

const ContractExecutionStateAggr1 = ({ contractExecutionStateAggr }) => {
    return <Chart
        type='bar'
        maxHeight={300}
        maxWidth={600}
        data={{
            labels: contractExecutionStateAggr.map(_ => _.contractor),
            datasets: [{
                label: 'В работе',
                data: contractExecutionStateAggr.map(_ => _.inWork),
                backgroundColor: [
                    'rgba(164, 206, 255, 0.4)'
                ],
                borderWidth: 1,
                borderRadius: 5,
                datalabels: {
                    anchor: 'end',
                    align: 'top',
                    color: 'rgba(164, 206, 255, 1)'
                }
            }, {
                label: 'В работе с отклонением срока',
                data: contractExecutionStateAggr.map(_ => _.inWorkWithProblem),
                backgroundColor: [
                    'rgba(255, 109, 62, 1)'
                ],
                borderWidth: 1,
                barPercentage: 0.7,
                borderRadius: 5,
                datalabels: {
                    anchor: 'start',
                    align: 'bottom',
                    color: 'rgba(255, 109, 62, 1)'
                }
            }, {
                label: 'Исполнен',
                data: contractExecutionStateAggr.map(_ => _.executed),
                backgroundColor: [
                    'rgba(118, 199, 194, 1)'
                ],
                borderWidth: 1,
                borderRadius: 5,
                stack: 'staked1',
                datalabels: {
                    display: true,
                    anchor: 'start',
                    align: 'bottom',
                    color: 'rgba(118, 199, 194, 1)'
                }
            }, {
                label: 'Закрыт',
                data: contractExecutionStateAggr.map(_ => _.closed),
                backgroundColor: [
                    'rgba(102, 131, 164, 1)'
                ],
                borderWidth: 1,
                barThickness1: 30,
                borderRadius: 5,
                borderSkipped1: false,
                stack: 'staked2',
                datalabels: {
                    display: true,
                    anchor: 'start',
                    align: 'bottom',
                    color: 'rgba(102, 131, 164, 1)'
                }
            }
            ]
        }}
        options={{
            plugins: {
                datalabels: {
                    display: true,
                    anchor: 'end',
                    align: 'top'
                }
            },
            scales: {
                xAxes: {
                    stacked: true,
                    grid: {
                        display: true
                    }
                },
                yAxes: {
                    display: false
                }
            }
        }} />
}