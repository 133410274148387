import React, { useEffect, useRef } from 'react';
import Map from 'ol/Map'

export default ({options}) => {
  const mapRef = useRef()

  // initialize map on first render - logic formerly put into componentDidMount
  useEffect(() => {
    // create map
    if (mapRef.current) {
      const initialMap = new Map({
        ...options,
        target: mapRef.current
      })
    }

  }, [options])

  // render component
  return  <div ref={mapRef} style={{height: '100vh', width: '100%'}}></div>
}