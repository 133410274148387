import React from 'react';
import Chart from './Chart'
import './style.css';

const ContractExecutionState = ({contractExecutionState}) => {
    return <React.Fragment>
        <h2>3.1 Исполнение по договорам - В2 (<a href="https://www.chartjs.org/docs/latest/samples/bar/horizontal.html">Chart.js Horizontal BarChart</a>)</h2>
        {contractExecutionState.map((c, idx) => <div key={idx}>
            <Block contractExecutionStateSingle={c} />
        </div>)}
    </React.Fragment>
}

const Block = ({ contractExecutionStateSingle }) => {
    return <Chart
        type='bar'
        maxHeight={contractExecutionStateSingle.byCurrency.length * 100 + 20}
        maxWidth={"100%"}
        data={{
            labels: contractExecutionStateSingle.byCurrency.map(_ => _.currency),
            datasets: [{
                data: contractExecutionStateSingle.byCurrency.map(_ => 0),
                backgroundColor: [
                    '#6683A4'
                ],
                borderWidth: 1,
                borderRadius: 5,
                datalabels1: {
                    anchor: 'end',
                    align: 'top',
                    color: 'rgba(164, 206, 255, 1)'
                },
                stack: 'stack1'
            },
            {
                label: 'Фактически исполнено обязательств',
                data: contractExecutionStateSingle.byCurrency.map(_ => _.workFact),
                backgroundColor: [
                    '#6683A4'
                ],
                borderWidth: 1,
                borderRadius: 5,
                datalabels1: {
                    anchor: 'end',
                    align: 'top',
                    color: 'rgba(164, 206, 255, 1)'
                },
                stack: 'stack1'
            }, {
                label: 'Текущий остаток по договору',
                data: contractExecutionStateSingle.byCurrency.map(_ => _.workRest),
                backgroundColor: [
                    '#97C1F2'
                ],
                borderWidth: 1,
                borderRadius: 5,
                datalabels1: {
                    anchor: 'start',
                    align: 'bottom',
                    color: 'rgba(255, 109, 62, 1)'
                },
                stack: 'stack1'
            }, {
                label: 'Зачет аванса',
                data: contractExecutionStateSingle.byCurrency.map(_ => _.paidPrepaidExpense),
                backgroundColor: [
                    '#76C7C2'
                ],
                borderWidth: 1,
                borderRadius: 5,
                datalabels1: {
                    anchor: 'start',
                    align: 'bottom',
                    color: 'rgba(255, 109, 62, 1)'
                },
                stack: 'stack2'
            }, {
                label: 'Фактический платеж',
                data: contractExecutionStateSingle.byCurrency.map(_ => _.paidFact),
                backgroundColor: [
                    '#FFCCB6'
                ],
                borderWidth: 1,
                borderRadius: 5,
                datalabels1: {
                    anchor: 'start',
                    align: 'bottom',
                    color: 'rgba(255, 109, 62, 1)'
                },
                stack: 'stack2'
            }, {
                label: 'Отложенные платежи и удержания',
                data: contractExecutionStateSingle.byCurrency.map(_ => _.paidDeferred),
                backgroundColor: [
                    '#E9A1A1'
                ],
                borderWidth: 1,
                borderRadius: 5,
                datalabels1: {
                    anchor: 'start',
                    align: 'bottom',
                    color: 'rgba(255, 109, 62, 1)'
                },
                stack: 'stack2'
            }
            ]
        }}
        options={{
            indexAxis: 'y',
            plugins: {
                datalabels1: {
                    display: true,
                    anchor: 'end',
                    align: 'top'
                }
            },
            scales: {
                xAxes: {
                    grid: {
                        display: false
                    }
                },
                yAxes: {
                    //stacked: true,
                    grid: {
                        display: false
                    }
                }
            }
        }} />
}

export default ContractExecutionState;