import React from 'react';
import Chart from './Chart'
import './style.css';

const Task = ({ task }) => {
    return <React.Fragment>
        <h2>7.2 Задачи - B2 (<a href="https://www.chartjs.org/docs/latest/charts/doughnut.html">Chart.js Doughnut</a>)</h2>
        <Chart
        type='doughnut'
        maxHeight={300}
        maxWidth={300}
        data={{
            labels: ['Активные', 'Завершенные'],
            datasets: [{
                data: [task.inWork, task.completed],
                backgroundColor: [
                    '#97C1F2', '#6683A4'
                ],
                radius: 100,
                cutout: 95,
                datalabels: {  
                }
            }, {
                data: [task.inWorkWithProblem, task.inWork - task.inWorkWithProblem, task.completedWithProblem, task.completed - task.completedWithProblem],
                backgroundColor: [
                    '#E55324', 'transparent', '#E55324', 'transparent'
                ],
                radius: 95,
                cutout: 90,
                datalabels: {
                    formatter: (value, context) => {
                        return context.dataIndex === 1 || context.dataIndex === 3
                            ? null
                            : value
                    }
                }
            }
            ]
        }}
        options={{
            plugins: {
                datalabels: {
                    display: true,
                    anchor: 'center',
                    align: 'center'
                }
            }
        }} />
        </React.Fragment>
}

export default Task;