export let contractExecutionStateAggr = [
    {
        contractor: "Контрагент 1",
        inWork: 70,
        inWorkWithProblem: 30,
        executed: 50,
        closed: 25
    },
    {
        contractor: "Контрагент 2",
        inWork: 60,
        inWorkWithProblem: 15,
        executed: 105,
        closed: 55
    },
    {
        contractor: "Контрагент 3",
        inWork: 50,
        inWorkWithProblem: 40,
        executed: 20,
        closed: 100
    },
    {
        contractor: "Контрагент 4",
        inWork: 60,
        inWorkWithProblem: 50,
        executed: 50,
        closed: 15
    },
    {
        contractor: "Контрагент 5",
        inWork: 30,
        inWorkWithProblem: 15,
        executed: 5,
        closed: 50
    }
]
export let contractCostChange = [
    {
        num: "1234567890",
        start: [
            {currency: 'РУБ.', value: 100}
        ],
        changes: [
            {
                name: 'ДС1',
                changes: [
                    {currency: 'РУБ.', value: 15},
                ]
            },
            {
                name: 'ДС2',
                changes: [
                    {currency: 'РУБ.', value: -40},
                ]
            },
            {
                name: 'ДС3',
                changes: [
                    {currency: 'РУБ.', value: 20},
                ]
            },
            {
                name: 'ДС4',
                changes: [
                    {currency: 'РУБ.', value: 25},
                ]
            },
            {
                name: 'ДС5',
                changes: [
                    {currency: 'РУБ.', value: -25},
                ]
            },
            {
                name: 'ДС6',
                changes: [
                    {currency: 'РУБ.', value: 25},
                ]
            }
        ]
    },
    {
        num: "1234567891",
        start: [
            {currency: 'РУБ.', value: 100}
        ],
        changes: [
            {
                name: 'ДС1',
                changes: [
                    {currency: 'РУБ.', value: 10},
                ]
            },
            {
                name: 'ДС2',
                changes: [
                    {currency: 'РУБ.', value: -20},
                ]
            }
        ]
    },
    {
        num: "1234567892",
        start: [
            {currency: 'РУБ.', value: 50},
            {currency: 'USD', value: 130}
        ],
        changes: [
            {
                name: 'ДС1',
                changes: [
                    {currency: 'РУБ.', value: -20},
                    {currency: 'USD', value: -10}
                ]
            },
            {
                name: 'ДС2',
                changes: [
                    {currency: 'РУБ.', value: 10},
                    {currency: 'USD', value: 10}
                ]
            },
            {
                name: 'ДС3',
                changes: [
                    {currency: 'РУБ.', value: 15},
                    {currency: 'USD', value: -20}
                ]
            },
            {
                name: 'ДС4',
                changes: [
                    {currency: 'РУБ.', value: -30},
                    {currency: 'USD', value: -10}
                ]
            }
        ]
    }
]
export let contractExecutionState = [
    {
        num: "1234567890",
        byCurrency: [
            {
                currency: 'РУБ.',
                workProc: 70,
                paidProc: 70,
                workFact: 90,
                workRest: 45,
                paidPrepaidExpense: 30,
                paidFact: 30,
                paidDeferred: 15
            }
        ] 
    },
    {
        num: "1234567899",
        byCurrency: [
            {
                currency: 'РУБ.',
                workProc: 85,
                paidProc: 70,
                workFact: 30,
                workRest: 6,
                paidPrepaidExpense: 0,
                paidFact: 24,
                paidDeferred: 6
            },
            {
                currency: 'USD',
                workProc: 85,
                paidProc: 70,
                workFact: 30,
                workRest: 6,
                paidPrepaidExpense: 0,
                paidFact: 24,
                paidDeferred: 6
            }
        ] 
    }
]

export let task = {
    inWork: 10,
    inWorkWithProblem: 1,
    completed: 4,
    completedWithProblem: 2
}