import React, {useState} from 'react';
import { AgChartsReact } from 'ag-charts-react';
import './style.css';

// eslint-disable-next-line
export default ({contractExecutionStateAggr}) => {
    const [axesEnabled, setAxesEnabled] = useState(true);
    const [labelEnabled, setLabelEnabled] = useState(false);
    const [gridEnabled, setGridEnabled] = useState(false);
    return <React.Fragment>
        <h2>1.1 Статус исполнения договора - В1 (<a href="https://www.ag-grid.com/react-charts/gallery/grouped-column/">AgChart Grouped Column</a>)</h2>
        <input type="checkbox" checked={axesEnabled} onChange={(e) => {setAxesEnabled(e.target.checked)}} /><label>Оси</label>
        <input type="checkbox" checked={gridEnabled} onChange={(e) => {setGridEnabled(e.target.checked)}} /><label>Сетка</label>
        <input type="checkbox" checked={labelEnabled} onChange={(e) => {setLabelEnabled(e.target.checked)}} /><label>Подписи</label>
        <ContractExecutionStateAggr1 contractExecutionStateAggr={contractExecutionStateAggr} axesEnabled={axesEnabled} labelEnabled={labelEnabled} gridEnabled={gridEnabled} />
    </React.Fragment>
}

const ContractExecutionStateAggr1 = ({contractExecutionStateAggr, axesEnabled, labelEnabled, gridEnabled }) => {
    return <div>
        <Block contractExecutionStateAggr={contractExecutionStateAggr} axesEnabled={axesEnabled} labelEnabled={labelEnabled} gridEnabled={gridEnabled}/>
    </div>
}

const Block = ({ contractExecutionStateAggr, axesEnabled, labelEnabled, gridEnabled }) => {
    let data = contractExecutionStateAggr.map((i) => ({
        contractor: i.contractor,
        inWork: i.inWork,
        inWorkWithProblem: i.inWorkWithProblem,
        executed: i.executed,
        closed: i.closed,
        k1: undefined,
        k2: undefined
    }));

    const options = {
        autoSize: true,
        data: data,
        series: [
            {
                type: 'column',
                xKey: 'contractor',
                yKeys: [
                    'inWork',
                    'executed',
                    'closed'
                ],
                grouped: true,
                yNames: [
                    'В работе',
                    'Исполнен',
                    'Закрыт'
                ],
                fills: ['#97C1F2', '#76C7C2', '#6683A4'],
                strokes: ['#97C1F2', '#76C7C2', '#6683A4'],
                label: {
                    enabled: labelEnabled,
                    placement: 'outside'
                }
            },
            {
                type: 'column',
                xKey: 'contractor',
                yKeys: [
                    'inWorkWithProblem',
                    'k1',
                    'k2'
                ],
                grouped: true,
                yNames: [
                    'В работе с отклонением сроков',
                    ' ',
                    ' '
                ],
                fills: ['#E55324', '', ''],
                strokes: ['#E55324', '', ''],
                label: {
                    enabled: labelEnabled,
                    placement: 'outside'
                }
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom'
            },
            {
                type: 'number',
                position: 'left'
            },
        ]
    }
    if (gridEnabled === false) {
        options.axes[0].gridStyle = {stroke: 'transparent', lineDash: null};
        options.axes[1].gridStyle = {stroke: 'transparent', lineDash: null};
    }
    if (axesEnabled === false) {
        options.axes[0].line = {width: 0};
        options.axes[1].line = {width: 0};
        options.axes[1].label = {color: 'transparent'};
    }
    return <AgChartsReact options={options} />
}